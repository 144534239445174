.hero > ul {
  font-size: 1em;
  list-style: none;
}

.hero > ul + ul {
  margin-top: 1em;
}

.hero > ul > li {
  padding-bottom: 0.5em;
}

.hero > pre {
  padding: 3em 0;
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: left;
  display: inline-block;
}
