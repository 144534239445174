:root {
  --main-bg-color: black;
  --main-text-color: #eee;
  --main-border-color: #888;
}

html {
  margin: 0;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--main-text-color);
  background: var(--main-bg-color);

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 1024px) {
  pre.logo {
    font-size: 4px;
  }
}
