.code > pre {
  white-space: pre-wrap;
  text-align: left;
  display: inline-block;
  display: block;
  overflow-x: scroll;
}

@media only screen and (max-width: 1024px) {
  .code > pre {
    zoom: 0.5;
  }
}
