.rules > ul {
  font-size: 1em;
  list-style: none;
}

.rules > ul > li {
  padding-bottom: 0.5em;
}

.rules > pre {
  padding: 3em 0;
  white-space: pre-wrap;
  text-align: left;
  display: inline-block;
}
