.deploy > ul {
  font-size: 1em;
  list-style: none;
  margin-bottom: 2em;
}

.deploy > ul > li {
  padding-bottom: 0.5em;
}

.deploy > ul > li > input {
  background: none;
  border: none;
  font-family: monospace;
  color: var(--main-text-color);
  width: 32em;
  outline: none;
}

.deploy > ul > li > input:focus {
  background: white;
  color: black;
}

.deploy > ul > li > input:focus::placeholder {
  color: transparent;
}

.deploy > pre {
  padding: 3em 0;
  margin: 0 auto;
  white-space: pre-wrap;
  text-align: left;
  display: inline-block;
}

.deploy > ul > li > button {
  background: none;
  cursor: pointer;
  border: none;
  font-family: monospace;
  color: var(--main-text-color);
  outline: none;
  padding: 0em;
}

.deploy > ul > li > a {
  background: none;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-family: monospace;
  color: var(--main-text-color);
  outline: none;
  padding: 0em;
}

.deploy > ul > li > button:focus {
  background: white;
  color: black;
}
