.app {
  height: 100svh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  outline: none;
}

.app > section {
  padding: 2em;
  min-height: 100svh;
  scroll-snap-align: start;
  position: relative;
}

.app > section:not(:last-of-type)::after {
  content: '[ Press ↓ to continue ]';
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

@media (hover: none) {
  .app > section:not(:last-of-type)::after {
    content: '[ Scroll to continue ]';
  }
}
